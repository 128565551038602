<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Enter Details
                  </h3>
                  <div class="wizard-desc">
                    Setup Your Device Details
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Select Services
                  </h3>
                  <div class="wizard-desc">
                    Choose Your Notification
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/General/Like.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Completed!
                  </h3>
                  <div class="wizard-desc">
                    Review and Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->
        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Enter the Details of this physical device
                  </h4>
                  <p>
                    In this wizard you will create a custom device that you can
                    use for your own devices or APIs
                  </p>
                  <div class="form-group">
                    <label>Location of device</label>
                    <b-form-input
                      id="device-location"
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Where is this device located?"
                      v-model="device_location"
                      :state="deviceLocationValidation"
                      aria-describedby="device-location-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="device-location-feedback">
                      Enter at least 5 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Where is this device located? ie Kitchen, garage,
                      etc</span
                    >
                  </div>

                  <!-- <div class="form-text">Name of the device</div> -->
                  <div class="form-group">
                    <label>Name of the device</label>
                    <b-form-input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Name"
                      value="Bulb"
                      v-model="device_name"
                      :state="deviceNameValidation"
                      aria-describedby="device-name-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="device-name-feedback">
                      Enter at least 5 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Write the name of the device, ie bulb, lamp, leds etc
                    </span>
                  </div>

                  <!-- <div class="form-text">Token</div> -->
                  <div class="form-group">
                    <label>Token</label>
                    <b-form-input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Add your token"
                      v-model="device_token"
                      :state="deviceTokenValidation"
                      aria-describedby="device-token-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="device-token-feedback">
                      Enter at least 7 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Enter the token provided with your device
                    </span>
                  </div>

                  <!-- <div class="form-text">Secret Token</div> -->
                  <div class="form-group">
                    <label>Secret Token</label>
                    <b-form-input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Add your secret token"
                      v-model="secret_token"
                      :state="deviceSecretTokenValidation"
                      aria-describedby="device-secrettoken-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="device-secrettoken-feedback">
                      Enter at least 7 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Enter the secret token provided with your device
                    </span>
                  </div>

                  <!-- <div class="form-text">Description</div> -->
                  <div class="form-group">
                    <label>Description</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="device_description"
                      placeholder="Enter something that describes your device..."
                      rows="3"
                      :state="deviceDescriptionValidation"
                      max-rows="6"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="device-name-feedback">
                      Enter at least 10 letters
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Select your notification schema
                  </h4>
                  <div class="form-group">
                    <b-form-group label="Notify online/offline:">
                      <b-form-checkbox-group
                        id="checkbox-group-2"
                        v-model="notifications_selected"
                        name="flavour-2"
                      >
                        <b-form-checkbox value="email">Email</b-form-checkbox>
                        <b-form-checkbox value="messenger"
                          >FB Messenger</b-form-checkbox
                        >
                        <b-form-checkbox value="twitter"
                          >Twitter</b-form-checkbox
                        >
                        <b-form-checkbox value="telegram"
                          >Telegram</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                    <div>
                      Selected: <strong>{{ notifications_selected }}</strong>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Is this device public?</label>
                    <select
                      name="packaging"
                      class="form-control form-control-solid form-control-lg"
                      v-model="device_public"
                    >
                      <option value="">Select availability</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Review your physical device details and Submit
                  </h4>
                  <div class="border-bottom mb-5 pb-5">
                    <div class="font-weight-bold mb-3">
                      Device details
                    </div>
                    <div class="line-height-md">
                      {{ device_name }} in {{ device_location }}
                      <br />
                      Technically identified as {{ device_token }}/{{
                        secret_token
                      }}<br />
                      {{ device_description }}
                    </div>
                  </div>
                  <div class="border-bottom mb-5 pb-5">
                    <div class="font-weight-bold mb-3">
                      Notification settings:
                    </div>
                    <div class="line-height-md">
                      {{ notifications_selected }}
                      <br />
                      Public device: {{ device_public }} <br />
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      v-on:click="submit"
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-submit"
                    >
                      Submit
                    </button>
                    <button
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CREATE_PHYSICAL_DEVICE,
  SET_ERROR,
} from "@/core/services/store/devices.module";
import { DEVICES_TECHNICAL_NAMES } from "@/core/enums/devices-technical-names.enum";
import { SOURCES } from "@/core/enums/sources.enum";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Wizard-2",
  computed: {
    deviceLocationValidation() {
      return this.device_location.length > 5 ? true : false;
    },
    deviceNameValidation() {
      return this.device_name.length > 5 ? true : false;
    },
    deviceTokenValidation() {
      return this.device_token.length > 7 ? true : false;
    },
    deviceSecretTokenValidation() {
      return this.secret_token.length > 7 ? true : false;
    },
    deviceDescriptionValidation() {
      return this.device_description.length > 10 ? true : false;
    },
  },
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  mounted() {
    this.unsubscribe=this.$store.subscribe((mutation, state) => {
      // console.log("mutation-->",mutation.type, state)
      if (mutation.type === SET_ERROR) {
        this.devicesList = state.devices.devices;
        Swal.fire({
          title: "",
          text: "The application found an issue on the last operation",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-2" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });
    this.global_wizard = wizard;

    // Validation before going to next page
    wizard.on("change", (wizardObj) => {
      // validate the form and use below function to stop the wizard's step
      if (wizardObj.getStep() == 1 && this.validateStepOne() == false) {
        wizardObj.stop();
        Swal.fire({
          title: "",
          text: "There is still some information missing",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if (wizardObj.getStep() == 2 && this.validateStepTwo() == false) {
        wizardObj.stop();
        Swal.fire({
          title: "",
          text: "Select device availability",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  data() {
    return {
      unsubscribe: null,
      global_wizard: {},
      notifications_selected: [],
      device_description: "",
      device_name: "",
      device_location: "",
      device_token: "",
      secret_token: "",
      device_public: "",
    };
  },
  methods: {
    validateStepOne: function() {
      if (
        this.device_description.length < 10 ||
        this.device_name.length < 5 ||
        this.device_location < 5 ||
        this.device_token < 7 ||
        this.secret_token < 7
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateStepTwo: function() {
      if (this.device_public != "") {
        return true;
      }
      return false;
    },
    tellme() {
      alert(this.global_wizard.getStep());
    },
    submit: function(e) {
      e.preventDefault();
      // here we create the object to send
      this.$store
        .dispatch(CREATE_PHYSICAL_DEVICE, {
          source: SOURCES.WEB,
          claim: "yes",
          anet_device: {
            object: this.device_name,
            location: this.device_location,
            token: this.device_token,
            secret_token: this.secret_token,
            description: this.device_description,
            is_public: this.device_public == "Yes" ? "1" : "0",
            notify_email:
              this.notifications_selected.indexOf("email") != -1 ? "1" : "0",
            notify_twitter:
              this.notifications_selected.indexOf("twitter") != -1 ? "1" : "0",
            notify_telegram:
              this.notifications_selected.indexOf("telegram") != -1 ? "1" : "0",
            version: DEVICES_TECHNICAL_NAMES[this.device_type],
            notify_fbinbox:
              this.notifications_selected.indexOf("messenger") != -1
                ? "1"
                : "0",
          },
        })
        .then((resultOfCreation) => {
          if (resultOfCreation.result == "success") {
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            //if everything went smooth, then remove all information and take the user to step 1 in case
            //he wants to add another one
            this.global_wizard.goTo(1);
            this.notifications_selected = [];
            this.device_location = "";
            this.device_description = "";
            this.device_public = "";
            this.device_name = "";
            this.device_token = "";
            this.secret_token = "";
          } else {
            Swal.fire({
              title: "",
              text: "The application found an issue on the last operation",
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        });
    },
  },
};
</script>
